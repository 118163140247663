// Core
import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { isMobile } from "react-device-detect"

// Utils
import { blockSwitch } from "../../utils/blockSwitch"
import { blockList } from "../../utils/blockList"

// Components
import EpisodeMenu from "../../components/episodeMenu"
import SEO from "../../components/seo"
import Hero from "../../components/hero"
import Footer from "../../components/footer"
import { maskIn } from "../../utils/masks"

const About = props => {
  const [menuOpen, setMenuOpen] = useState(false),
    [menuHidden, setMenuHidden] = useState(false),
    [menuCurrent, setMenuCurrent] = useState(1),
    [menuTimeout, setMenuTimeout] = useState(null)

  let header = useRef(null),
    epScrolled = useRef(false)

  useEffect(() => {
    header.current = document.getElementById("header")

    setTimeout(() => {
      if (!isMobile && window.scroll.update) {
        window.scroll.update()
      }
    }, 200)

    if (header.current) header.current.setAttribute("scrolled", false)

    if (!isMobile) window.scroll.update()

    maskIn()

    setMenuTimeout(
      setTimeout(() => {
        setMenuOpen(true)
      }, 100)
    )

    return () => {
      if (menuTimeout) clearTimeout(menuTimeout)
    }
  }, [])

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const showMenu = () => {
    setMenuOpen(true)
    setMenuHidden(false)
  }

  const hideMenu = () => {
    setMenuOpen(false)
    setMenuHidden(true)
  }

  const scrolledHero = way => {
    if (way === "exit" && !epScrolled.current) {
      epScrolled.current = true
      if (header.current) header.current.setAttribute("scrolled", epScrolled.current)
      setMenuOpen(true)
    } else if (way === "enter" && epScrolled.current) {
      epScrolled.current = false
      if (header.current) header.current.setAttribute("scrolled", epScrolled.current)
      setMenuOpen(false)
    }
  }

  const page = props.data.allContentfulAboutPage.nodes[0],
    { title } = page,
    { episodeVimeoLink } = page,
    { seoTitle } = page,
    { seoDescription } = page

  const episodeInfo = {
    title: title,
  }

  const blockScrollEvent = (way, count, obj) => {
    if (way === "enter") {
      setMenuCurrent(count + 1)
      return
    }
  }

  const blocks = blockSwitch(
      page.episodeBuilder,
      episodeInfo,
      blockScrollEvent
    ),
    list = blockList(page.episodeBuilder)

  return (
    <React.Fragment>
      <SEO
        title={seoTitle}
        description={seoDescription ? seoDescription.seoDescription : null}
      />
      <Hero
        title={title}
        soundStage={true}
        loop={episodeVimeoLink}
        scrolledEvent={scrolledHero}
        openMenu={showMenu}
        closeMenu={hideMenu}
      />
      {blocks}
      <EpisodeMenu
        toggleMenu={toggleMenu}
        open={menuOpen}
        hidden={menuHidden}
        list={list}
        current={menuCurrent}
      />
      <Footer />
    </React.Fragment>
  )
}

export default About

export const aboutQuery = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAboutPage(limit: 1, filter: { slug: { ne: "sample" } }) {
      nodes {
        contentful_id
        slug
        title
        episodeVimeoLink
        seoTitle
        seoDescription {
          seoDescription
        }
        seoImage {
          fluid(
            maxWidth: 1200
            maxHeight: 630
            cropFocus: CENTER
            quality: 85
            resizingBehavior: FILL
          ) {
            src
          }
        }
        episodeBuilder {
          ... on ContentfulEssay {
            id
            contentful_id
            menuTitle
            authorImage {
              description
              fluid(
                quality: 85
                cropFocus: CENTER
                maxHeight: 760
                maxWidth: 560
                resizingBehavior: FILL
              ) {
                src
              }
            }
            heading
            subheading
            content {
              json
            }
          }
          ... on ContentfulImageGallery {
            contentful_id
            imageGalleryItems {
              contentful_id
              mobileImage {
                fluid(
                  maxWidth: 768
                  maxHeight: 900
                  cropFocus: FACE
                  quality: 85
                  resizingBehavior: FILL
                ) {
                  src
                }
              }
              desktopImageCropped: image {
                fluid(
                  maxWidth: 768
                  maxHeight: 900
                  cropFocus: FACE
                  quality: 85
                  resizingBehavior: FILL
                ) {
                  src
                }
              }
              desktopImage: image {
                description
                fluid(
                  cropFocus: CENTER
                  maxHeight: 1800
                  maxWidth: 3600
                  quality: 85
                  resizingBehavior: FILL
                ) {
                  src
                }
              }
              caption {
                json
              }
            }
          }
          ... on ContentfulInterview {
            contentful_id
            menuTitle
            subheading
            heading
            description {
              json
            }
            vimeoVideoId
            posterImage {
              description
              fluid(
                cropFocus: CENTER
                maxHeight: 916
                maxWidth: 1630
                quality: 85
                resizingBehavior: FILL
              ) {
                src
              }
            }
          }
          ... on ContentfulPerformanceIntro {
            contentful_id
            introParagraph {
              json
            }
            contributingArtists
            secondaryText
          }
          ... on ContentfulPlaylist {
            contentful_id
            bannerImage {
              description
              fluid(
                maxHeight: 1700
                maxWidth: 3600
                quality: 85
                resizingBehavior: FILL
                cropFocus: CENTER
              ) {
                src
              }
            }
            heading
            subheading
            content {
              json
            }
            artworkGrid {
              contentful_id
              description
              fluid(
                maxHeight: 432
                maxWidth: 432
                cropFocus: CENTER
                resizingBehavior: FILL
                quality: 85
              ) {
                src
              }
            }
            spotifyUrl
          }
          ... on ContentfulProgramNotes {
            contentful_id
            programNoteItems {
              contentful_id
              heading
              subheadingRich {
                json
              }
              content {
                json
              }
              details {
                json
              }
            }
          }
          ... on ContentfulVideoGallery {
            contentful_id
            subheading
            posterImage {
              description
              fluid(
                maxHeight: 1000
                maxWidth: 1776
                cropFocus: CENTER
                quality: 85
                resizingBehavior: FILL
              ) {
                src
              }
            }
            videoGalleryItems {
              contentful_id
              heading
              vimeoVideoId
            }
          }
        }
      }
    }
  }
`
